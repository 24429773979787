import * as React from "react";
import {useState} from "react";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import {
  FeatureGroup,
  GeoJSON,
  LayerGroup,
  LayersControl,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
} from "react-leaflet";
import L, {LeafletEvent, Map as LeafletMap} from "leaflet";
import {Comp} from "../../Comp";
import "leaflet.awesome-markers/dist/leaflet.awesome-markers";
import {GeoJsonObject} from "geojson";
// @ts-ignore
import hash from "object-hash";
import shp from "shpjs";
// @ts-ignore
import JSZipUtils from "jszip-utils";

export interface MapLatLng {
  lat: number;
  lng: number;
}

function MouseEventsMap(props: {setLocation(latLng: MapLatLng): void}) {
  useMapEvents({
    contextmenu: (event) => {
      event.originalEvent.preventDefault();
      props.setLocation(event.latlng);
      return false;
    },
  });
  return null;
}

function showCompsOnMap(mousedOverTransactionID?: number, comps?: Comp[]) {
  return comps?.map((comp: Comp) => {
    const position: MapLatLng = {
      lat: comp.latitude,
      lng: comp.longitude,
    };
    const compIcon = L.AwesomeMarkers.icon({
      prefix: "fa",
      icon: "",
      markerColor:
        mousedOverTransactionID == comp.transaction_id ? "red" : "cadetblue",
      html: comp.rank.toString(),
    });
    let z_index = 0;
    if (mousedOverTransactionID == comp.transaction_id) {
      z_index = 100;
    }
    return (
      <Marker
        position={position}
        icon={compIcon}
        key={comp.transaction_id}
        zIndexOffset={z_index}
      >
        <Popup>
          <a href={comp.redfin_url} target="_blank" rel="noopener noreferrer">
            {comp.street}
          </a>
        </Popup>
      </Marker>
    );
  });
}

function showSearchBufferOnMap(searchBuffer: GeoJsonObject) {
  return (
    <GeoJSON
      key={hash(searchBuffer)}
      data={searchBuffer}
      pathOptions={{fillOpacity: 0.0}}
    />
  );
}

function nameToColor(name: string) {
  name = name.toLowerCase();
  if (name.includes("a nei")) {
    return "#006600";
  } else if (name.includes("a/b nei")) {
    return "#00CC00";
  } else if (name.includes("b nei")) {
    return "#55FF44";
  } else if (name.includes("c nei")) {
    return "#FFFF00";
  } else if (name.includes("b/c/d nei")) {
    return "#666600";
  } else if (name.includes("c/d nei")) {
    return "#444400";
  } else if (name.includes("d nei")) {
    return "#AA0000";
  }
}

function showIndyZonesMap() {
  const [layer, setLayer] = useState<GeoJsonObject>();
  const addEvent = (_: LeafletEvent) => {
    if (layer !== undefined) {
      return;
    }
    const downloadUrl = new URL(
      "../../shp/2020.0.9.27-indianapolis-zones-map_SHP.zip",
      import.meta.url
    );
    fetch(downloadUrl)
      .then((text) => text.arrayBuffer())
      .then((buffer) => shp(buffer))
      .then(function (geojson) {
        const layer = geojson as GeoJsonObject;
        setLayer(layer);
      });
  };
  return (
    <LayerGroup eventHandlers={{add: addEvent}}>
      {layer &&
        // @ts-ignore
        layer.features.map((feature, index) => {
          const name = feature.properties.Name;
          const color = nameToColor(name);
          return (
            <FeatureGroup key={index}>
              <Popup>
                <p>{name}</p>
              </Popup>
              <GeoJSON
                data={feature.geometry}
                pathOptions={{
                  dashArray: [40, 10, 20, 30],
                  color: color,
                  opacity: 0.5,
                  fillOpacity: 0.2,
                }}
              />
            </FeatureGroup>
          );
        })}
    </LayerGroup>
  );
}

function showLayer() {
  const [layer, setLayer] = useState<GeoJsonObject>();
  const addEvent = (_: LeafletEvent) => {
    if (layer !== undefined) {
      return;
    }
    const downloadUrl = new URL(
      "../../shp/kx_indianapolis_indiana_neighborhoods_SHP.zip",
      import.meta.url
    );
    fetch(downloadUrl)
      .then((text) => text.arrayBuffer())
      .then((buffer) => shp(buffer))
      .then(function (geojson) {
        const layer = geojson as GeoJsonObject;
        setLayer(layer);
      });
  };
  return (
    <LayerGroup eventHandlers={{add: addEvent}}>
      {layer &&
        // @ts-ignore
        layer.features.map((feature, index) => {
          return (
            <FeatureGroup key={index}>
              <Popup>
                <p>{feature.properties.Neighborho}</p>
              </Popup>
              <GeoJSON
                data={feature.geometry}
                pathOptions={{
                  dashArray: [40, 10, 20, 30],
                  color: "#5522FF",
                  opacity: 0.5,
                  fillOpacity: 0.0,
                }}
              />
            </FeatureGroup>
          );
        })}
    </LayerGroup>
  );
}

interface MapDrawerProps {
  geocodedAddress?: MapLatLng;
  comps?: Comp[];
  searchBuffer?: GeoJsonObject;
  mapCenter: MapLatLng;
  zoomLevel: number;
  drawerWidth: string;
  mousedOverTransactionID?: number;

  setLocation?(location: MapLatLng): void;
}

const MapDrawer = React.forwardRef<LeafletMap, MapDrawerProps>(
  (props, leafletMapRef) => {
    const homeMarker = L.AwesomeMarkers.icon({
      prefix: "fa",
      icon: "home",
      markerColor: "blue",
    });

    return (
      <Drawer
        sx={{
          width: props.drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: props.drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        <MapContainer
          ref={leafletMapRef}
          className="comp-map"
          center={props.mapCenter}
          zoom={props.zoomLevel}
          scrollWheelZoom={false}
        >
          {props.setLocation && (
            <MouseEventsMap setLocation={props.setLocation} />
          )}
          <LayersControl position="topright">
            <LayersControl.Overlay name="Neighborhood Map">
              {showLayer()}
            </LayersControl.Overlay>
            <LayersControl.Overlay name="Zones Map (circa 2020)">
              {showIndyZonesMap()}
            </LayersControl.Overlay>
          </LayersControl>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {props.geocodedAddress && (
            <Marker
              position={props.geocodedAddress}
              icon={homeMarker}
              key="geocodedAddress"
              zIndexOffset={100}
            />
          )}
          {props.searchBuffer && showSearchBufferOnMap(props.searchBuffer)}
          {showCompsOnMap(props.mousedOverTransactionID, props.comps)}
        </MapContainer>
      </Drawer>
    );
  }
);
export default MapDrawer;
