import {useParams} from "react-router-dom";
import {useList} from "@refinedev/core";
import React, {useEffect} from "react";
import {
  DataGrid,
  GridColDef,
  GridColTypeDef,
  GridColumnHeaderParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import GradeOutlinedIcon from "@mui/icons-material/GradeOutlined";
import {Stack} from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import WcOutlinedIcon from "@mui/icons-material/WcOutlined";
import NightlifeOutlinedIcon from "@mui/icons-material/NightlifeOutlined";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import DirectionsCarOutlinedIcon from "@mui/icons-material/DirectionsCarOutlined";
import StraightenOutlinedIcon from "@mui/icons-material/StraightenOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import {monthDiff} from "../../calendar";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import {CompTransactionType, DealType} from "../../dataProvider/types";
import {useForm} from "@refinedev/react-hook-form";
import {getComps} from "../../api";
import {Comp, scoreAllComps} from "../../Comp";
import {DealCompPopover} from "./DealCompPopover";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";

function getRating(params: GridValueGetterParams): string {
  if (!params.row?.reviews || params.row?.reviews.length == 0) {
    return "";
  }
  return params.row?.reviews[params.row?.reviews.length - 1].is_good_comp;
}

const CURRENCY_FORMAT = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
const USD_PRICE_COLUMN: GridColTypeDef = {
  type: "number",
  width: 100,
  valueFormatter: ({value}) => CURRENCY_FORMAT.format(Number(value)),
  cellClassName: "font-tabular-nums",
};
const YEAR_FORMAT = new Intl.NumberFormat("en-US", {
  style: "decimal",
  useGrouping: false,
});
const YEAR_COLUMN: GridColTypeDef = {
  type: "number",
  width: 80,
  valueFormatter: ({value}) => YEAR_FORMAT.format(Number(value)),
  cellClassName: "font-tabular-nums",
};

export const DealComps = (props: {
  comps?: Comp[];
  setComps?(comps: Comp[]): void;
}) => {
  const {id} = useParams();
  const {data, isLoading} = useList<CompTransactionType>({
    resource: "comp_transaction",
    filters: [
      {
        field: "deal_id",
        operator: "eq",
        value: id,
      },
    ],
  });
  const {
    refineCore: {queryResult},
  } = useForm<DealType>({
    refineCoreProps: {
      redirect: false,
      successNotification: false,
      metaData: {
        id: "id",
      },
    },
  });

  const deal: DealType | undefined = queryResult?.data?.data;
  useEffect(() => {
    if (deal && deal.address) {
      const req = {
        address: deal.address,
        ...(deal.bedrooms_total && {bedrooms: deal.bedrooms_total}),
        ...(deal.bathrooms_total && {bathrooms: deal.bathrooms_total}),
        ...(deal.living_area_sf && {living_area_sf: deal.living_area_sf}),
        ...(deal.build_year && {build_year: deal.build_year}),
        ...(deal.latitude &&
          deal.longitude && {
            map_center: {
              lat: deal.latitude,
              lng: deal.longitude,
            },
          }),
      };
      getComps(req).then(
        ({latLng: _latLng, comps, searchBuffer: _searchBuffer}) => {
          props.setComps && props.setComps(comps);
        }
      );
    }
  }, [deal]);
  const target = {
    ...(deal?.bedrooms_total && {bedrooms: deal.bedrooms_total}),
    ...(deal?.bathrooms_total && {bathrooms: deal.bathrooms_total}),
    ...(deal?.living_area_sf && {living_area_sf: deal.living_area_sf}),
    ...(deal?.build_year && {build_year: deal.build_year}),
    ...(deal?.garage_spaces && {
      has_garage: deal?.garage_spaces ?? 0 > 0,
      garage_spaces: deal.garage_spaces,
    }),
  };
  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "rating",
        headerName: "Rating",
        width: 10,
        renderHeader: (_params: GridColumnHeaderParams) => (
          <strong>
            <span role="img" aria-label="enjoy">
              <GradeOutlinedIcon />
            </span>
          </strong>
        ),
        valueGetter: getRating,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <Stack direction="row" spacing={2}>
              <DealCompPopover
                data={params.row?.reviews || []}
                row={params.row}
              />
            </Stack>
          );
        },
      },
      {
        field: "street",
        headerName: "Address",
        minWidth: 200,
        renderHeader: (_params: GridColumnHeaderParams) => (
          <strong>
            <span role="img" aria-label="enjoy">
              <HomeOutlinedIcon />
            </span>
          </strong>
        ),
      },
      {
        field: "price",
        headerName: "Price",
        renderHeader: (_params: GridColumnHeaderParams) => (
          <strong>
            <span role="img" aria-label="enjoy">
              <PaidOutlinedIcon />
            </span>
          </strong>
        ),
        ...USD_PRICE_COLUMN,
      },
      {
        field: "bedrooms",
        headerName: "Beds",
        type: "number",
        width: 50,
        renderHeader: (_params: GridColumnHeaderParams) => (
          <strong>
            <span role="img" aria-label="enjoy">
              <BedOutlinedIcon />
            </span>
          </strong>
        ),
      },
      {
        field: "bathrooms",
        headerName: "Baths",
        type: "number",
        width: 50,
        renderHeader: (_params: GridColumnHeaderParams) => (
          <strong>
            <span role="img" aria-label="enjoy">
              <WcOutlinedIcon />
            </span>
          </strong>
        ),
      },
      {
        field: "redfin_living_area_sf",
        headerName: "Living Sf",
        type: "number",
        width: 80,
        renderHeader: (_params: GridColumnHeaderParams) => (
          <strong>
            <span role="img" aria-label="enjoy">
              <NightlifeOutlinedIcon />
            </span>
          </strong>
        ),
      },
      {
        field: "build_year",
        headerName: "Build Year",
        type: "number",
        renderHeader: (_params: GridColumnHeaderParams) => (
          <strong>
            <span role="img" aria-label="enjoy">
              <CakeOutlinedIcon />
            </span>
          </strong>
        ),
        ...YEAR_COLUMN,
      },
      {
        field: "garage_spaces",
        headerName: "Garage",
        type: "number",
        width: 50,
        renderHeader: (_params: GridColumnHeaderParams) => (
          <strong>
            <span role="img" aria-label="enjoy">
              <DirectionsCarOutlinedIcon />
            </span>
          </strong>
        ),
        renderCell: (params) => {
          return (
            <>
              {params.row.garage_spaces || (params.row.has_garage ? "√" : "X")}
            </>
          );
        },
      },
      {
        field: "distance_miles",
        headerName: "Distance Miles",
        type: "number",
        width: 80,
        renderHeader: (_params: GridColumnHeaderParams) => (
          <strong>
            <span role="img" aria-label="enjoy">
              <StraightenOutlinedIcon />
            </span>
          </strong>
        ),
      },
      {
        field: "transaction_date",
        headerName: "Sale Date",
        type: "number",
        width: 80,
        renderHeader: (_params: GridColumnHeaderParams) => (
          <strong>
            <span role="img" aria-label="enjoy">
              <CalendarMonthOutlinedIcon />
            </span>
          </strong>
        ),
        valueGetter: ({value}) => {
          const saleDate = value;
          if (saleDate) {
            return monthDiff(new Date(saleDate), new Date()) + " mo";
          } else {
            return "";
          }
        },
      },
      {
        field: "reviews",
        headerName: "Reviews",
        type: "string",
        flex: 1,
        renderHeader: (_params: GridColumnHeaderParams) => (
          <strong>
            <span role="img" aria-label="enjoy">
              <CommentOutlinedIcon />
            </span>
          </strong>
        ),
        valueGetter: ({value}) => {
          if (!value || value.length == 0) {
            return "";
          } else {
            return value[value.length - 1].review_body;
          }
        },
      },
    ],
    []
  );
  const scoredComps = scoreAllComps(target, props.comps ?? []);
  const transactionIDs = data?.data.map((d) => d.transaction_id);
  return (
    <>
      {!isLoading && (
        <DataGrid
          columns={columns}
          autoHeight
          disableColumnMenu
          loading={isLoading}
          rows={([] as Object[]).concat(
            data?.data || [],
            scoredComps.filter(
              (comp) => !transactionIDs?.includes(comp.transaction_id)
            )
          )}
          getRowId={(row) => row.transaction_id}
          initialState={{
            sorting: {
              sortModel: [{field: "rank", sort: "asc"}],
            },
          }}
        />
      )}
    </>
  );
};
