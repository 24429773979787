import axios, {AxiosResponse} from "axios";
import {Comp} from "./Comp";
import type {GeoJsonObject} from "geojson";
import {SchemaName} from "./dataProvider/types";
import {PropertyInfo} from "./PropertyInfo";

export interface BootstrapConfig {
  auth0_domain: string;
  auth0_client_id: string;
  db_schema: SchemaName;
}

export const getBootstrapConfig = async (): Promise<BootstrapConfig> => {
  return axios.get("/api/bootstrap").then((resp) => Promise.resolve(resp.data));
};

export interface GetCompsRequest {
  address: string;
  bedrooms?: number;
  bathrooms?: number;
  living_area_sf?: number;
  living_area_min_sf?: number;
  living_area_max_sf?: number;
  build_year?: number;
  map_center?: {lat: number; lng: number};
}

export interface GeocodedAddress {
  latitude: number;
  longitude: number;
}

export interface GetCompsResponse {
  geocoded_address: GeocodedAddress;
  comps: Comp[];
  search_buffer_geojson: GeoJsonObject;
}

export const getComps = async (req: GetCompsRequest) => {
  const queryString = new URLSearchParams({
    address: req.address,
    ...(req.bedrooms && {bedrooms: req.bedrooms.toString()}),
    ...(req.bathrooms && {bathrooms: req.bathrooms.toString()}),
    ...(req.living_area_sf && {living_area_sf: req.living_area_sf.toString()}),
    ...(req.living_area_min_sf && {
      living_area_min_sf: req.living_area_min_sf.toString(),
    }),
    ...(req.living_area_max_sf && {
      living_area_max_sf: req.living_area_max_sf.toString(),
    }),
    ...(req.build_year && {build_year: req.build_year.toString()}),
    ...(req.map_center && {map_center_lat: req.map_center?.lat.toString()}),
    ...(req.map_center && {map_center_lng: req.map_center?.lng.toString()}),
  });
  const res: AxiosResponse<GetCompsResponse> = await axios.get(
    `/api/comp/${req.address}?${queryString}`,
    {}
  );
  const data = res.data;
  const geocoded = data.geocoded_address;
  const latLng = {lat: geocoded.latitude, lng: geocoded.longitude};
  const comps = data.comps;
  const searchBuffer = data.search_buffer_geojson;
  return {latLng, comps, searchBuffer};
};
export const lookupPropertyInfo = (address: string) => {
  return axios.get(`/api/lookup/${address}`).then((res) => {
    return res.data as PropertyInfo;
  });
};
