import React, {useState} from "react";
import {MapLatLng} from "./pages/arv/MapDrawer";
import {Comp} from "./Comp";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {useAuth0} from "@auth0/auth0-react";
import {useForm} from "react-hook-form";
import axios from "axios";
import {APP_PRODUCT_NAME} from "./constants";

export interface FeedbackModalProps {
  show: boolean;
  address: string;
  mapCenter: MapLatLng;
  comps: Array<Comp> | null | undefined;
  setShow(show: boolean): void;
  displayAlert(message: string): void;
}

export default function FeedbackModal(props: FeedbackModalProps) {
  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
  } = useForm();
  const {user} = useAuth0();

  const handleClose = () => {
    props.setShow(false);
  };

  const comps = props.comps?.map((c) => c.id).join(",");

  const [submitted, setSubmitted] = useState<boolean>(false);
  const closeDialog = () => {
    props.setShow(false);
    setSubmitted(false);
  };

  const onSubmit = (data: any) => {
    const formData: {[key: string]: any} = {
      email: user?.email ?? data.email,
      feedbackType: data.feedbackType,
      comments: data.comments,
      address: props.address,
      latitude: props.mapCenter?.lat,
      longitude: props.mapCenter?.lng,
      comps: comps,
    };
    axios
      .post(`/api/form/231438280414047`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        setSubmitted(true);
        reset();
      })
      .catch(() => {
        props.displayAlert(
          "Oops, something went wrong. Please try again later."
        );
      });
  };

  return (
    <Dialog open={props.show} onClose={handleClose} maxWidth={false}>
      {!submitted ? (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <DialogTitle>{APP_PRODUCT_NAME} Feedback</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please share feedback or suggestions for {APP_PRODUCT_NAME}!
            </DialogContentText>
            <Grid container spacing={2}>
              {!user?.email ? (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    margin="dense"
                    id="name"
                    label="Email Address"
                    type="email"
                    fullWidth
                    variant="outlined"
                    required
                    error={!!errors.email}
                    helperText={errors.email ? "Email Address is Required" : ""}
                    {...register("email", {
                      required: true,
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Valid Email Address is Required",
                      },
                    })}
                  />
                </Grid>
              ) : null}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <FormControl fullWidth error={!!errors.feedbackType}>
                  <InputLabel required>Feedback Type</InputLabel>
                  <Select
                    id="feedbackType"
                    label="Feedback Type"
                    defaultValue=""
                    required
                    {...register("feedbackType", {required: true})}
                  >
                    <MenuItem value="Bug">Bug</MenuItem>
                    <MenuItem value="Feature Request">Feature Request</MenuItem>
                  </Select>
                  {errors.feedbackType ? (
                    <FormHelperText margin="dense">
                      Feedback Type is Required
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  margin="dense"
                  id="comment"
                  label="Comments"
                  multiline
                  rows={4}
                  fullWidth
                  required
                  error={!!errors.comments}
                  helperText={errors.comments ? "Comments are Required" : ""}
                  {...register("comments", {required: true})}
                />
              </Grid>
            </Grid>
            <DialogActions>
              <Button onClick={closeDialog}>Cancel</Button>
              <Button type="submit">Submit</Button>
            </DialogActions>
          </DialogContent>
        </form>
      ) : (
        <div>
          <DialogContent>
            <DialogContentText>
              Your feedback has been recorded. Thank you!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Close</Button>
          </DialogActions>
        </div>
      )}
    </Dialog>
  );
}
