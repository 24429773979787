import {getComparator, stableSort} from "./stableSort";

export interface Comp {
  // ID of the Comp from the API query. Only unique within a query.
  id: number;
  rank: number;
  score: number;
  distance_miles: number;
  street: string;
  city: string;
  state: string;
  price: number;
  transaction_date: string;
  transaction_type: string;
  mls_id: number;
  build_year: number;
  bedrooms: number;
  bathrooms: number;
  living_sq_ft: number;
  latitude: number;
  longitude: number;
  transaction_id: number;
  redfin_url: string;

  redfin_living_area_sf: number;

  living_area_min_sf: number;
  living_area_max_sf: number;
  has_garage: boolean;
  garage_spaces: number;
}

export interface TargetProperty {
  bedrooms?: number;
  bathrooms?: number;
  build_year?: number;
  living_area_sf?: number;
  garage_spaces?: number;
}

export interface CompRankParams {
  distance_miles: number;
  build_year: number;
  bedrooms: number;
  bathrooms: number;
  redfin_living_area_sf: number;
  has_garage: boolean;
  garage_spaces: number;
}

export class MatchQuality {
  static Worst = Symbol("Worst");
  static Medium = Symbol("Medium");
  static Best = Symbol("Best");
}

type CompComparator = (
  target: TargetProperty,
  comp: CompRankParams
) => MatchQuality;

export class MatchingClass {
  static Bedrooms = new MatchingClass((target, comp) => {
    if (target.bedrooms === undefined) {
      return MatchQuality.Worst;
    }
    if (target.bedrooms == comp.bedrooms) {
      return MatchQuality.Best;
    } else {
      return MatchQuality.Worst;
    }
  });
  static Bathrooms = new MatchingClass((target, comp) => {
    if (target.bathrooms === undefined) {
      return MatchQuality.Worst;
    }
    if (target.bathrooms == comp.bathrooms) {
      return MatchQuality.Best;
    } else if (Math.abs(target.bathrooms - comp.bathrooms) <= 0.5) {
      return MatchQuality.Medium;
    } else {
      return MatchQuality.Worst;
    }
  });
  static Distance = new MatchingClass((target, comp) => {
    if (comp.distance_miles <= 0.5) {
      return MatchQuality.Best;
    } else if (comp.distance_miles <= 0.75) {
      return MatchQuality.Medium;
    } else {
      return MatchQuality.Worst;
    }
  });
  static LivingArea = new MatchingClass((target, comp) => {
    if (target.living_area_sf === undefined) {
      return MatchQuality.Worst;
    }
    const diff =
      Math.abs(target.living_area_sf - comp.redfin_living_area_sf) /
      target.living_area_sf;
    if (diff <= 0.2) {
      return MatchQuality.Best;
    } else if (diff <= 0.3) {
      return MatchQuality.Medium;
    } else {
      return MatchQuality.Worst;
    }
  });
  static Age = new MatchingClass((target, comp) => {
    const BANDS = Array(
      [0, 1900],
      /* 50 year bands */
      [1900, 1960],
      /* 20 year bands */
      [1960, 1980],
      [1980, 2000],
      /* 10 year bands */
      [2000, 2010],
      /* 5 year bands */
      [2010, 2015],
      /* 3 year bands */
      [2015, 2018],
      /* 3 year bands */
      [2018, 2021],
      /* 1 year bands */
      [2022, 2023],
      [2023, Number.MAX_SAFE_INTEGER]
    );
    const getBand = (buildYear: number) => {
      for (const [i, band] of BANDS.entries()) {
        if (band[0] <= buildYear && buildYear < band[1]) {
          return i;
        }
      }
      return -1;
    };
    if (target.build_year === undefined) {
      return MatchQuality.Worst;
    }
    const targetBand = getBand(target.build_year);
    const compBand = getBand(comp.build_year);
    if (targetBand == compBand) {
      return MatchQuality.Best;
    } else if (Math.abs(targetBand - compBand) == 1) {
      return MatchQuality.Medium;
    } else {
      return MatchQuality.Worst;
    }
  });

  comparator: CompComparator;

  constructor(comparator: CompComparator) {
    this.comparator = comparator;
  }
}

export function scoreComp(property: TargetProperty, comp: CompRankParams) {
  const rankedClasses = [
    MatchingClass.Bedrooms,
    MatchingClass.Bathrooms,
    MatchingClass.Distance,
    MatchingClass.LivingArea,
    MatchingClass.Age,
  ];
  let score: number = 0;
  rankedClasses.forEach((matchClass) => {
    const result = matchClass.comparator(property, comp);
    switch (result) {
      case MatchQuality.Best:
        score += 2;
        break;
      case MatchQuality.Medium:
        score += 1;
        break;
      case MatchQuality.Worst:
        score += 0;
        break;
    }
    score <<= 2;
  });
  return score;
}

export function scoreAllComps(property: TargetProperty, comps: Comp[]) {
  comps.forEach((comp) => {
    comp.score = scoreComp(property, comp as CompRankParams);
  });
  Object.entries(stableSort(comps, getComparator("desc", "score"))).forEach(
    ([i, comp_i]) => {
      comp_i.rank = Number(i) + 1;
    }
  );
  return comps;
}
