import {Controller} from "react-hook-form";
import CurrencyFormat from "react-currency-format";
import {InputAdornment, TextField, Theme} from "@mui/material";
import React, {ReactElement, ReactNode} from "react";
import {UseControllerProps} from "react-hook-form/dist/types/controller";
import {UseFormReturn} from "react-hook-form/dist/types/form";
import {SxProps} from "@mui/system";
import {TextFieldProps} from "@mui/material/TextField/TextField";

function isNumeric(n: any) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

const TextFieldWithProps = (inputProps: TextFieldProps) => (
  <TextField {...inputProps} />
);

export function CurrencyTextField(props: {
  name: string;
  label?: ReactNode;
  placeholder?: string;
  icon?: ReactElement;
  register?: UseFormReturn["register"];
  control?: UseControllerProps["control"];
  sx?: SxProps<Theme>;
  value?: string | number;
  required?: boolean;
}) {
  if (props.control && props.register) {
    const {ref: _ref, ...controllerProps} = props.register(props.name, {
      ...(props.required && {required: "This field is required"}),
      valueAsNumber: true,
    });
    return (
      <Controller
        control={props.control}
        {...controllerProps}
        render={({field: {onChange, onBlur, value, name}}) => (
          <CurrencyFormat
            id={`input-with-icon-${name}`}
            customInput={TextFieldWithProps}
            thousandSeparator
            prefix="$"
            decimalScale={0}
            placeholder={props.placeholder}
            label={props.label}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{props.icon}</InputAdornment>
              ),
            }}
            variant="standard"
            sx={{width: "12ch"}}
            onBlur={onBlur}
            onValueChange={(value) =>
              onChange(isNumeric(value?.floatValue) ? value?.floatValue : 0)
            }
            value={value || undefined}
            displayType={"input"}
          />
        )}
      />
    );
  } else {
    return (
      <CurrencyFormat
        id={`input-with-icon-${props.name}`}
        customInput={TextFieldWithProps}
        thousandSeparator
        prefix="$"
        decimalScale={0}
        placeholder={props.placeholder}
        label={props.label}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{props.icon}</InputAdornment>
          ),
          readOnly: true,
        }}
        variant="standard"
        sx={{width: "12ch"}}
        value={props.value}
        displayType={"input"}
      />
    );
  }
}
