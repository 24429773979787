import {useParams} from "react-router-dom";
import {useCreate, useList} from "@refinedev/core";
import {GridValidRowModel} from "@mui/x-data-grid";
import {FormControl, IconButton, Stack, TextField} from "@mui/material";
import {CompTransactionType} from "../../dataProvider/types";
import PopupState, {bindPopover, bindTrigger} from "material-ui-popup-state/es";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ClearIcon from "@mui/icons-material/Clear";
import {useForm, SubmitHandler} from "react-hook-form";

type Inputs = {
  reviewBody: string;
  isGoodComp: Boolean;
};

export const DealCompPopover = (props: {
  data: Array<{is_good_comp: boolean; review_body: string}>;
  row: GridValidRowModel;
}) => {
  const {mutate} = useCreate();
  const {id} = useParams();
  const {refetch} = useList<CompTransactionType>({
    resource: "comp_transaction",
    filters: [
      {
        field: "deal_id",
        operator: "eq",
        value: id,
      },
    ],
  });
  const reviewTexts = props.data.map((item) => item?.review_body);
  const lastIsGoodComp = props.data[props.data.length - 1]
    ? props.data[props.data.length - 1].is_good_comp
    : false;
  const color = lastIsGoodComp ? "yellow" : "white";

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: {errors},
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    mutate(
      {
        resource: "comp_review",
        values: {
          auth0_uid: "Replaced by Trigger",
          deal_id: Number(id),
          transaction_id: props.row.transaction_id,
          is_good_comp: data.isGoodComp,
          review_body: data.reviewBody,
        },
        successNotification: false,
        errorNotification: () => {
          return {
            message: `Something went wrong when creating a comp review`,
            description: "Error",
            type: "error",
          };
        },
      },
      {
        onError: (error) => {
          console.error("Error comp_review", error);
        },
        onSuccess: (data) => {
          console.log("Created comp_review", data);
          refetch();
          reset();
        },
      }
    );
  };

  return (
    <PopupState
      variant="popover"
      popupId="demo-popup-popover"
      disableAutoFocus={true}
      parentPopupState={null}
    >
      {(popupState) => (
        <>
          <Stack {...bindTrigger(popupState)}>
            {reviewTexts.length === 0 || lastIsGoodComp ? (
              <StarOutlinedIcon
                sx={{color: color, stroke: "#000000", strokeWidth: 2}}
              />
            ) : (
              <ClearIcon
                sx={{color: color, stroke: "#000000", strokeWidth: 2}}
              />
            )}
          </Stack>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            {reviewTexts.length != 0
              ? props.data.map((item, id) => (
                  <Typography key={id} sx={{p: 2}}>
                    {item?.review_body}
                  </Typography>
                ))
              : null}
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <FormControl>
                <Stack direction="row" sx={{p: 2}}>
                  <TextField
                    hiddenLabel
                    variant="filled"
                    size="small"
                    placeholder="Review Comp"
                    {...register("reviewBody", {required: true})}
                    required
                    error={!!errors.reviewBody}
                    helperText={
                      errors.reviewBody ? "Please Enter Review Details" : ""
                    }
                  />
                  <IconButton
                    type="submit"
                    onClick={() => setValue("isGoodComp", false)}
                  >
                    <ThumbDownIcon />
                  </IconButton>
                  <IconButton
                    type="submit"
                    onClick={() => setValue("isGoodComp", true)}
                  >
                    <ThumbUpIcon />
                  </IconButton>
                </Stack>
              </FormControl>
            </form>
          </Popover>
        </>
      )}
    </PopupState>
  );
};
