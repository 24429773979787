import {Route, Routes, useLocation} from "react-router-dom";
import AppLoadingIndicator from "./AppLoadingIndicator";
import Arv from "./pages/arv/Arv";
import {useAuth0} from "@auth0/auth0-react";
import React, {useEffect, useState} from "react";
import {
  ErrorComponent,
  notificationProvider,
  RefineSnackbarProvider,
} from "@refinedev/mui";
import DealPage from "./ui/pages/DealPage";
import DealList from "./ui/organisms/DealList";
import {Refine} from "@refinedev/core";
import {dataProvider} from "./dataProvider/postgrest";
import {PostgrestClient} from "@supabase/postgrest-js";
import routerBindings from "@refinedev/react-router-v6";
import {DealEdit} from "./ui/organisms/DealEdit";
import {DealComps} from "./ui/organisms/DealComps";
import {Comp} from "./Comp";
import {RefineKbarProvider} from "@refinedev/kbar";
import {APP_HEADER_PREFIX} from "./constants";
import {SchemaDatabase, SchemaName} from "./dataProvider/types";
import {lookupPropertyInfo} from "./api";

type AuthenticatedAppProps = {
  dbSchema: SchemaName;
};

// We need this component that is nested under a Auth0Provider
// to be able to use the auth0 utilities.
const AuthenticatedApp: React.FC<AuthenticatedAppProps> = (
  props: AuthenticatedAppProps
) => {
  const [comps, setComps] = useState<Comp[]>([]);
  const [postgrestClient, setPostgrestClient] =
    useState<PostgrestClient<SchemaDatabase, SchemaName>>();
  const {getAccessTokenSilently, isAuthenticated, isLoading} = useAuth0();
  const {search} = useLocation();

  useEffect(() => {
    if (isLoading) {
      return;
    }
    const createPostgrestClient = async () => {
      const headers: Record<string, string> = {};
      const params = new URLSearchParams(search);
      const access_key = params.get("access_key");
      if (access_key != null) {
        headers[`${APP_HEADER_PREFIX}Access-Key`] = access_key;
      }

      if (isAuthenticated) {
        const accessToken = await getAccessTokenSilently();
        headers["Authorization"] = `Bearer ${accessToken}`;
      }
      const client = new PostgrestClient<SchemaDatabase, SchemaName>(
        `${window.location.origin}/api2`,
        {
          headers: headers,
          schema: props.dbSchema,
          fetch: fetch,
        }
      );
      setPostgrestClient(client);
    };
    createPostgrestClient().catch(console.error);
  }, [getAccessTokenSilently, isAuthenticated, isLoading]);

  if (isLoading || postgrestClient == null) {
    return <AppLoadingIndicator />;
  }
  return (
    <RefineKbarProvider>
      <RefineSnackbarProvider>
        <Refine
          routerProvider={routerBindings}
          dataProvider={dataProvider(postgrestClient)}
          notificationProvider={notificationProvider}
          resources={[
            {
              name: "deal",
              list: "/deal",
              edit: "/deal/:id",
            },
          ]}
          options={{
            syncWithLocation: true,
            warnWhenUnsavedChanges: true,
          }}
        >
          <Routes>
            <Route index element={<Arv />} />
            <Route path="deal" element={<DealPage />}>
              <Route
                index
                element={<DealList postgrestClient={postgrestClient} />}
              />
              <Route
                path=":id"
                element={
                  <>
                    <DealEdit lookupPropertyInfo={lookupPropertyInfo} />
                    <DealComps comps={comps} setComps={setComps} />
                  </>
                }
              />
            </Route>
            <Route path="*" element={<ErrorComponent />} />
          </Routes>
        </Refine>
      </RefineSnackbarProvider>
    </RefineKbarProvider>
  );
};

export default AuthenticatedApp;
