import * as React from "react";
import {ReactElement} from "react";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import NightlifeOutlinedIcon from "@mui/icons-material/NightlifeOutlined";
import StraightenOutlinedIcon from "@mui/icons-material/StraightenOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import Box from "@mui/material/Box";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import DirectionsCarOutlinedIcon from "@mui/icons-material/DirectionsCarOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import Paper from "@mui/material/Paper";
import SquareFootOutlinedIcon from "@mui/icons-material/SquareFootOutlined";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import WcOutlinedIcon from "@mui/icons-material/WcOutlined";
import {Comp, MatchingClass, MatchQuality, TargetProperty} from "../../Comp";
import {styled} from "@mui/material/styles";
import {tableCellClasses} from "@mui/material";
import {visuallyHidden} from "@mui/utils";
import {getComparator, Order, stableSort} from "../../stableSort";
import {monthDiff} from "../../calendar";

type HeadDisplay = "label" | "icon";

interface HeadCell {
  disablePadding: boolean;
  id: keyof Comp;
  label: string;
  numeric: boolean;
  icon?: ReactElement;
  display: HeadDisplay[];
}

const headCells: readonly HeadCell[] = [
  {
    id: "score",
    numeric: false,
    disablePadding: true,
    label: "Score",
    icon: <LocationOnOutlinedIcon />,
    display: ["icon"],
  },
  {
    id: "street",
    numeric: false,
    disablePadding: true,
    label: "Street",
    icon: <HomeOutlinedIcon />,
    display: ["icon"],
  },
  {
    id: "price",
    numeric: true,
    disablePadding: true,
    label: "Price",
    icon: <PaidOutlinedIcon />,
    display: ["icon"],
  },
  {
    id: "bedrooms",
    numeric: true,
    disablePadding: true,
    label: "Beds",
    icon: <BedOutlinedIcon />,
    display: ["icon"],
  },
  {
    id: "bathrooms",
    numeric: true,
    disablePadding: true,
    label: "Baths",
    icon: <WcOutlinedIcon />,
    display: ["icon"],
  },
  {
    id: "living_sq_ft",
    numeric: true,
    disablePadding: true,
    label: "",
    icon: <SquareFootOutlinedIcon />,
    display: ["label", "icon"],
  },
  {
    id: "living_area_min_sf",
    numeric: true,
    disablePadding: true,
    label: "Living Area",
    icon: <NightlifeOutlinedIcon />,
    display: ["icon"],
  },
  {
    id: "build_year",
    numeric: true,
    disablePadding: true,
    label: "Build Year",
    icon: <CakeOutlinedIcon />,
    display: ["icon"],
  },
  {
    id: "garage_spaces",
    numeric: true,
    disablePadding: true,
    label: "Garage Spaces",
    icon: <DirectionsCarOutlinedIcon />,
    display: ["icon"],
  },
  {
    id: "distance_miles",
    numeric: true,
    disablePadding: true,
    label: "Distance",
    icon: <StraightenOutlinedIcon />,
    display: ["icon"],
  },
  {
    id: "transaction_date",
    numeric: true,
    disablePadding: true,
    label: "Date",
    icon: <CalendarMonthOutlinedIcon />,
    display: ["icon"],
  },
];

interface CompTableProps {
  target: TargetProperty;
  comps: Comp[];

  setMousedOverTransactionID?(mousedOverTransactionID: number): void;
}

interface CompTableHeadProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Comp
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontWeight: "bold",
    padding: "4px 0px",
  },
  [`&.${tableCellClasses.head}:first-of-type`]: {
    borderTopLeftRadius: "4px",
  },
  [`&.${tableCellClasses.head}:last-child`]: {
    borderTopRightRadius: "4px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableSortLabel = styled(TableSortLabel)(({theme}) => ({
  icon: {
    display: "none",
  },
  active: {
    "& $icon": {
      display: "inline",
    },
  },
  "&:hover": {
    color: theme.palette.common.white,
  },
}));

function CompTableHead(props: CompTableHeadProps) {
  const {order, orderBy, onRequestSort} = props;
  const createSortHandler =
    (property: keyof Comp) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  return (
    <TableHead>
      <StyledTableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <StyledTableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.display
                .slice(0)
                .reverse()
                .map((headDisplay) =>
                  headDisplay == "icon" ? (
                    <Box key="icon" component="span">
                      {headCell.icon}
                    </Box>
                  ) : (
                    <Box key="label" component="span">
                      {headCell.label}
                    </Box>
                  )
                )}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </StyledTableSortLabel>
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );
}

export default function CompTable(props: CompTableProps) {
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<keyof Comp>("score");
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Comp
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleMouseOver = (transaction_id: number) => {
    props.setMousedOverTransactionID &&
      props.setMousedOverTransactionID(transaction_id);
  };
  const handleMouseOut = () => {
    props.setMousedOverTransactionID && props.setMousedOverTransactionID(0);
  };
  const handleClick = (
    event: React.MouseEvent<unknown>,
    transaction_id: number
  ) => {
    const selectedIndex = selected.indexOf(transaction_id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, transaction_id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.comps.length) : 0;

  const currencyFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  const numberFormat = new Intl.NumberFormat("en-US");

  const tableWidth = (width: number | string) => {
    return {
      width: width,
      maxWidth: width,
      overflow: "hidden",
      textOverflow: "ellipsis",
      borderStyle: "border-box",
    };
  };
  const highlightIf = (quality: MatchQuality) => {
    return {
      ...(quality == MatchQuality.Best && {
        color: "green",
        fontWeight: "bold",
      }),
      ...(quality == MatchQuality.Medium && {
        color: "orange",
        fontWeight: "bold",
      }),
      ...(quality == MatchQuality.Worst && {
        color: "darkred",
        fontWeight: "bold",
      }),
    };
  };
  const RowPaddingTableCell = styled(TableCell)({
    padding: "6px 6px",
  });
  const NoPaddingTableCell = styled(TableCell)({
    padding: 0,
  });
  const IconPaddingTableCell = styled(TableCell)({
    padding: "0px 8px",
  });

  return (
    <Box sx={{ml: 1, width: "98%"}}>
      <Paper sx={{width: "100%", mb: 2}}>
        <TableContainer>
          <Table
            sx={{
              minWidth: 500,
            }}
            aria-labelledby="tableTitle"
            size="small"
          >
            <CompTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={props.comps.length}
            />
            <TableBody>
              {stableSort(props.comps, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.transaction_id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const date = row.transaction_date
                    ? monthDiff(new Date(row.transaction_date), new Date()) +
                      " mo"
                    : "";
                  return (
                    <StyledTableRow
                      hover
                      onMouseOver={() => handleMouseOver(row.transaction_id)}
                      onMouseOut={() => handleMouseOut()}
                      onClick={(event) =>
                        handleClick(event, row.transaction_id)
                      }
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.transaction_id}
                      selected={isItemSelected}
                    >
                      <StyledTableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        sx={tableWidth("2ch")}
                      >
                        {row.rank}
                      </StyledTableCell>
                      <NoPaddingTableCell align="left" sx={tableWidth("16ch")}>
                        <a
                          href={row.redfin_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {row.street}
                        </a>
                      </NoPaddingTableCell>
                      <NoPaddingTableCell align="right" sx={tableWidth("10ch")}>
                        {currencyFormat.format(row.price)}
                      </NoPaddingTableCell>
                      <IconPaddingTableCell
                        align="right"
                        sx={tableWidth("2ch")}
                      >
                        <Box
                          component="span"
                          sx={highlightIf(
                            MatchingClass.Bedrooms.comparator(props.target, row)
                          )}
                        >
                          {row.bedrooms}
                        </Box>
                      </IconPaddingTableCell>
                      <IconPaddingTableCell
                        align="right"
                        sx={tableWidth("4ch")}
                      >
                        <Box
                          component="span"
                          sx={highlightIf(
                            MatchingClass.Bathrooms.comparator(
                              props.target,
                              row
                            )
                          )}
                        >
                          {row.bathrooms}
                        </Box>
                      </IconPaddingTableCell>
                      <RowPaddingTableCell align="right" sx={tableWidth("6ch")}>
                        {numberFormat.format(row.living_sq_ft)}
                      </RowPaddingTableCell>
                      <RowPaddingTableCell align="right" sx={tableWidth("6ch")}>
                        <Box
                          component="span"
                          sx={highlightIf(
                            MatchingClass.LivingArea.comparator(
                              props.target,
                              row
                            )
                          )}
                        >
                          {numberFormat.format(row.living_area_min_sf)}
                        </Box>
                      </RowPaddingTableCell>
                      <RowPaddingTableCell align="right" sx={tableWidth("4ch")}>
                        <Box
                          component="span"
                          sx={highlightIf(
                            MatchingClass.Age.comparator(props.target, row)
                          )}
                        >
                          {row.build_year}
                        </Box>
                      </RowPaddingTableCell>
                      <RowPaddingTableCell align="right" sx={tableWidth("1ch")}>
                        {row.garage_spaces || (row.has_garage ? "√" : "X")}
                      </RowPaddingTableCell>
                      <NoPaddingTableCell align="right" sx={tableWidth("7ch")}>
                        <Box
                          component="span"
                          sx={highlightIf(
                            MatchingClass.Distance.comparator(props.target, row)
                          )}
                        >
                          {Math.round(row.distance_miles * 100) / 100} mi
                        </Box>
                      </NoPaddingTableCell>
                      <NoPaddingTableCell align="right" sx={tableWidth("4ch")}>
                        {date}
                      </NoPaddingTableCell>
                    </StyledTableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={props.comps.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
