import {
  AppState,
  Auth0Provider,
  Auth0ProviderOptions,
} from "@auth0/auth0-react";
import React from "react";
import {useNavigate} from "react-router-dom";

interface Auth0ProviderWithNavigateProps extends Auth0ProviderOptions {
  redirectUri: string;
}

export default function Auth0ProviderWithNavigate(
  props: Auth0ProviderWithNavigateProps
) {
  // useNavigate() may be used only in the context of a <Router> component.
  const navigate = useNavigate();

  const domain = props.domain;
  const clientId = props.clientId;
  const redirectUri = props.redirectUri;

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        // Audience identifier for the API.
        // TODO: make this bootstrapped.
        audience: "https://dealflow-local/db",
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {props.children}
    </Auth0Provider>
  );
}
