import React from "react";
import {Alert, Snackbar} from "@mui/material";

export interface AlertBarProps {
  show: boolean;
  handleClose(): void;
  message: string;
}

export default function AlertBar(props: AlertBarProps) {
  return (
    <Snackbar
      anchorOrigin={{vertical: "top", horizontal: "center"}}
      open={props.show}
      onClose={props.handleClose}
    >
      <Alert onClose={props.handleClose} severity="error" sx={{width: "100%"}}>
        {props.message}
      </Alert>
    </Snackbar>
  );
}
