import React from "react";
import {EditButton, useDataGrid} from "@refinedev/mui";
import {
  DataGrid,
  GridColDef,
  GridColumnHeaderParams,
  GridEventListener,
} from "@mui/x-data-grid";

import FlashOnOutlinedIcon from "@mui/icons-material/FlashOnOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import {useGo} from "@refinedev/core";
import {
  useKbarDealActions,
  useKbarDealCreateAction,
} from "../../useKbarDealActions";
import {DealType, SchemaDatabase, SchemaName} from "../../dataProvider/types";
import {useKBar} from "@refinedev/kbar";
import {PostgrestClient} from "@supabase/postgrest-js";
import {Database} from "../../dataProvider/database.types";

export default function DealList(props: {
  postgrestClient: PostgrestClient<SchemaDatabase, SchemaName>;
}) {
  const {dataGridProps, tableQueryResult} = useDataGrid<DealType>({
    resource: "deal",
    errorNotification: (data) => {
      console.error("Error fetching deals", data);
      return {
        message: `Something went wrong when fetching deals`,
        description: "Error",
        type: "error",
      };
    },
  });
  useKbarDealActions(tableQueryResult.data?.data ?? [], [tableQueryResult]);
  const {searchQuery} = useKBar((state) => ({searchQuery: state.searchQuery}));
  useKbarDealCreateAction(searchQuery);
  const go = useGo();
  const rowClick: GridEventListener<"rowClick"> = (
    params, // GridRowParams
    _event, // MuiEvent<React.MouseEvent<HTMLElement>>
    _details // GridCallbackDetails
  ) => {
    go({
      to: `/deal/${params.row.id}?access_key=${params.row.access_key}`,
      type: "push",
    });
  };
  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "id",
        headerName: "Id",
        headerClassName: "dealflow-table-header",
        minWidth: 40,
        maxWidth: 70,
        renderHeader: (_params: GridColumnHeaderParams) => (
          <LocationOnOutlinedIcon />
        ),
        renderCell: function render({row}) {
          return <EditButton hideText resource="deal" recordItemId={row.id} />;
        },
      },
      {
        field: "address",
        headerName: "Address",
        headerClassName: "dealflow-table-header",
        minWidth: 100,
        flex: 1,
        renderHeader: (_params: GridColumnHeaderParams) => <HomeOutlinedIcon />,
      },
      {
        field: "offer_price",
        headerName: "Offer Price",
        headerClassName: "dealflow-table-header",
        type: "number",
        minWidth: 4,
        renderHeader: (_params: GridColumnHeaderParams) => (
          <>
            <ShoppingCartOutlinedIcon />
            Offer
          </>
        ),
      },
      {
        field: "after_repair_value",
        headerName: "ARV",
        headerClassName: "dealflow-table-header",
        type: "number",
        minWidth: 4,
        renderHeader: (_params: GridColumnHeaderParams) => (
          <>
            <FlashOnOutlinedIcon /> ARV
          </>
        ),
      },
      {
        field: "repair_estimate",
        headerName: "Repair",
        headerClassName: "dealflow-table-header",
        type: "number",
        minWidth: 4,
        renderHeader: (_params: GridColumnHeaderParams) => (
          <>
            <ConstructionOutlinedIcon /> Repair
          </>
        ),
      },
      {
        field: "profit",
        headerName: "Profit",
        headerClassName: "dealflow-table-header",
        type: "number",
        minWidth: 4,
        valueGetter: (params) => {
          const row = params.row;
          return row?.after_repair_value &&
            row?.offer_price &&
            row?.repair_estimate
            ? row.after_repair_value - row.offer_price - row.repair_estimate
            : "";
        },
        renderHeader: (_params: GridColumnHeaderParams) => (
          <>
            <MonetizationOnOutlinedIcon /> Profit
          </>
        ),
      },
    ],
    []
  );
  return (
    // @ts-ignore
    <DataGrid
      {...dataGridProps}
      columns={columns}
      autoHeight
      disableColumnMenu
      columnHeaderHeight={42}
      onRowClick={rowClick}
    />
  );
}
