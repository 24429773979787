import {
  CrudFilter,
  CrudOperators,
  DataProvider,
  HttpError,
} from "@refinedev/core";
import {PostgrestClient, PostgrestError} from "@supabase/postgrest-js";
import {Database} from "./database.types";
import {SchemaDatabase, SchemaName} from "./types";

const mapOperator = (operator: CrudOperators) => {
  switch (operator) {
    case "ne":
      return "neq";
    case "nin":
      return "not.in";
    case "contains":
      return "ilike";
    case "ncontains":
      return "not.ilike";
    case "containss":
      return "like";
    case "ncontainss":
      return "not.like";
    case "null":
      return "is";
    case "nnull":
      return "not.is";
    case "between":
    case "nbetween":
      throw Error(`Operator ${operator} is not supported`);
    default:
      return operator;
  }
};

const generateFilter = (filter: CrudFilter, query: any) => {
  switch (filter.operator) {
    case "eq":
      return query.eq(filter.field, filter.value);
    case "ne":
      return query.neq(filter.field, filter.value);
    case "in":
      return query.in(filter.field, filter.value);
    case "gt":
      return query.gt(filter.field, filter.value);
    case "gte":
      return query.gte(filter.field, filter.value);
    case "lt":
      return query.lt(filter.field, filter.value);
    case "lte":
      return query.lte(filter.field, filter.value);
    case "contains":
      return query.ilike(filter.field, `%${filter.value}%`);
    case "containss":
      return query.like(filter.field, `%${filter.value}%`);
    case "null":
      return query.is(filter.field, null);
    case "startswith":
      return query.ilike(filter.field, `${filter.value}%`);
    case "endswith":
      return query.ilike(filter.field, `%${filter.value}`);
    case "or":
      const orSyntax = filter.value
        .map((item) => {
          if (
            item.operator !== "or" &&
            item.operator !== "and" &&
            "field" in item
          ) {
            return `${item.field}.${mapOperator(item.operator)}.${item.value}`;
          }
          return;
        })
        .join(",");
      return query.or(orSyntax);

    case "and":
      throw Error("Operator 'and' is not supported");
    default:
      return query.filter(
        filter.field,
        mapOperator(filter.operator),
        filter.value
      );
  }
};

const handleError = (error: PostgrestError) => {
  const customError: HttpError = {
    ...error,
    message: error.message,
    statusCode: parseInt(error.code),
  };
  return Promise.reject(customError);
};

const dataProvider = (
  postgrestClient: PostgrestClient<SchemaDatabase, SchemaName>
): Required<DataProvider> => {
  return {
    getList: async ({resource, pagination, filters, sorters, meta}) => {
      const {current = 1, pageSize = 10, mode = "server"} = pagination ?? {};

      const query = postgrestClient.from(resource).select(meta?.select ?? "*", {
        count: "exact",
      });

      if (mode === "server") {
        query.range((current - 1) * pageSize, current * pageSize - 1);
      }

      sorters?.map((item) => {
        const [foreignTable, field] = item.field.split(/\.(.*)/);

        if (foreignTable && field) {
          query
            .select(meta?.select ?? `*, ${foreignTable}(${field})`)
            .order(field, {
              ascending: item.order === "asc",
              foreignTable: foreignTable,
            });
        } else {
          query.order(item.field, {
            ascending: item.order === "asc",
          });
        }
      });

      filters?.map((item) => {
        generateFilter(item, query);
      });

      const {data, count, error} = await query;

      if (error) {
        return handleError(error);
      }

      return {
        data: data || [],
        total: count || 0,
      } as any;
    },

    getMany: async ({resource, ids, meta}) => {
      const {data, error} = await postgrestClient
        .from(resource)
        .select(meta?.select ?? "*")
        .in(meta?.id ?? "id", ids);

      if (error) {
        return handleError(error);
      }

      return {
        data: data || [],
      } as any;
    },

    create: async ({resource, variables}) => {
      const {data, error} = await postgrestClient
        .from(resource)
        .insert(variables)
        .select();

      if (error) {
        return handleError(error);
      }

      return {
        data: (data || [])[0] as any,
      };
    },

    createMany: async ({resource, variables}) => {
      const {data, error} = await postgrestClient
        .from(resource)
        .insert(variables);

      if (error) {
        return handleError(error);
      }

      return {
        data: data as any,
      };
    },

    update: async ({resource, id, variables, meta}) => {
      const query = postgrestClient.from(resource).update(variables);

      if (meta?.id) {
        query.eq(meta?.id, id);
      } else {
        query.match({id});
      }

      const {data, error} = await query;
      if (error) {
        return handleError(error);
      }

      return {
        data: (data || [])[0] as any,
      };
    },

    updateMany: async ({resource, ids, variables, meta}) => {
      const response = await Promise.all(
        ids.map(async (id) => {
          const query = postgrestClient.from(resource).update(variables);

          if (meta?.id) {
            query.eq(meta?.id, id);
          } else {
            query.match({id});
          }

          const {data, error} = await query;
          if (error) {
            return handleError(error);
          }

          return (data || [])[0] as any;
        })
      );

      return {
        data: response,
      };
    },

    getOne: async ({resource, id, meta}) => {
      const query = postgrestClient.from(resource).select(meta?.select ?? "*");

      if (meta?.id) {
        query.eq(meta?.id, id);
      } else {
        query.match({id});
      }

      const {data, error} = await query;
      if (error) {
        return handleError(error);
      }

      return {
        data: (data || [])[0] as any,
      };
    },

    deleteOne: async ({resource, id, meta}) => {
      const query = postgrestClient.from(resource).delete();

      if (meta?.id) {
        query.eq(meta?.id, id);
      } else {
        query.match({id});
      }

      const {data, error} = await query;
      if (error) {
        return handleError(error);
      }

      return {
        data: (data || [])[0] as any,
      };
    },

    deleteMany: async ({resource, ids, meta}) => {
      const response = await Promise.all(
        ids.map(async (id) => {
          const query = postgrestClient.from(resource).delete();

          if (meta?.id) {
            query.eq(meta?.id, id);
          } else {
            query.match({id});
          }

          const {data, error} = await query;
          if (error) {
            return handleError(error);
          }

          return (data || [])[0] as any;
        })
      );

      return {
        data: response,
      };
    },

    getApiUrl: () => {
      throw Error("Not implemented on PostgREST data provider.");
    },

    custom: () => {
      throw Error("Not implemented on PostgREST data provider.");
    },
  };
};

export {dataProvider};
