import React, {createRef, useState} from "react";
import "../../App.css";
import MapDrawer, {MapLatLng} from "./MapDrawer";
import CssBaseline from "@mui/material/CssBaseline";
import {Map as LeafletMap} from "leaflet";
import SearchBar from "./SearchBar";
import {Stack} from "@mui/material";
import {Comp, scoreAllComps} from "../../Comp";
import Toolbar from "@mui/material/Toolbar";
import CompTable from "./CompTable";
import Box from "@mui/material/Box";
import {getComps, GetCompsRequest, lookupPropertyInfo} from "../../api";
import {GeoJsonObject} from "geojson";
import AlertBar from "../../AlertBar";
import {AlertBarProps} from "../../AlertBar";

function Arv() {
  const indyGeocode: MapLatLng = {lat: 39.791, lng: -86.148003};
  const closeAlert = () => {
    setAlertBarProps({show: false, message: "", handleClose: closeAlert});
  };
  const displayAlert = (message: string) => {
    setAlertBarProps({
      show: true,
      message: message,
      handleClose: closeAlert,
    });
  };
  const [alertBarProps, setAlertBarProps] = useState<AlertBarProps>({
    show: false,
    message: "",
    handleClose: closeAlert,
  });
  const [address, setAddress] = useState<string>("");
  const [geocodedAddress, setGeocodedAddress] = useState<MapLatLng>();
  const [comps, setComps] = useState<Comp[]>([]);
  const [searchBuffer, setSearchBuffer] = useState<GeoJsonObject>();
  const [bedrooms, setBedrooms] = useState<number>();
  const [bathrooms, setBathrooms] = useState<number>();
  const [buildYear, setBuildYear] = useState<number>();
  const [livingAreaSqFt, setLivingAreaSqFt] = useState<number>();
  const [hasGarage, setHasGarage] = useState<boolean>();
  const [garageSpaces, setGarageSpaces] = useState<number>();
  const [mousedOverTransactionID, setMousedOverTransactionID] =
    useState<number>(0);
  const [mapCenter, setMapCenter] = useState<MapLatLng>(indyGeocode);
  const [zoomLevel, setZoomLevel] = useState<number>(12);
  const leafletMap = createRef<LeafletMap>();
  const setLocation = (location: MapLatLng) => {
    console.log(`Set location ${location}`);
    const newAddr = `${location.lat}, ${location.lng}`;
    setAddress(newAddr);
    getComps({
      address: newAddr,
      bedrooms: bedrooms,
      bathrooms: bathrooms,
      living_area_sf: livingAreaSqFt,
      build_year: buildYear,
    })
      .then(({latLng, comps, searchBuffer}) => {
        setGeocodedAddress(latLng);
        setComps(comps);
        setSearchBuffer(searchBuffer);
      })
      .catch((reason) => {
        console.error(`Failed to get comps due to ${reason}.`);
      });
  };
  const lookupComps = (req: GetCompsRequest) => {
    req.map_center = {
      lat: mapCenter.lat,
      lng: mapCenter.lng,
    };
    getComps(req)
      .then(({latLng, comps, searchBuffer}) => {
        const newZoom = 15;
        setMapCenter(latLng);
        setZoomLevel(newZoom);
        setGeocodedAddress(latLng);
        setComps(comps);
        setSearchBuffer(searchBuffer);
        // Close popups to allow moving to the new view.
        leafletMap?.current?.closePopup();
        leafletMap?.current?.setView(latLng, newZoom);
      })
      .catch((error) => {
        console.log(error);
        setAlertBarProps({
          show: true,
          message: "Oops, something went wrong. Please try again later.",
          handleClose: closeAlert,
        });
      });
  };
  const drawerWidth = "40%";
  const target = {
    bedrooms: bedrooms,
    bathrooms: bathrooms,
    living_area_sf: livingAreaSqFt,
    build_year: buildYear,
    has_garage: hasGarage,
    garage_spaces: garageSpaces,
  };
  const scoredComps = scoreAllComps(target, comps);
  return (
    <Stack sx={{display: "flex"}}>
      <CssBaseline />
      <AlertBar {...alertBarProps} handleClose={closeAlert} />
      <SearchBar
        address={address}
        setAddress={setAddress}
        bedrooms={bedrooms}
        setBedrooms={setBedrooms}
        bathrooms={bathrooms}
        setBathrooms={setBathrooms}
        buildYear={buildYear}
        setBuildYear={setBuildYear}
        livingAreaSqFt={livingAreaSqFt}
        setLivingAreaSqFt={setLivingAreaSqFt}
        hasGarage={hasGarage}
        setHasGarage={setHasGarage}
        garageSpaces={garageSpaces}
        setGarageSpaces={setGarageSpaces}
        lookupComps={lookupComps}
        lookupPropertyInfo={lookupPropertyInfo}
        displayAlert={displayAlert}
        mapCenter={mapCenter}
        comps={comps}
      />
      <Stack>
        <MapDrawer
          ref={leafletMap}
          drawerWidth={drawerWidth}
          geocodedAddress={geocodedAddress}
          comps={scoredComps}
          searchBuffer={searchBuffer}
          mapCenter={mapCenter}
          zoomLevel={zoomLevel}
          mousedOverTransactionID={mousedOverTransactionID}
          setLocation={setLocation}
        />
        <Stack
          sx={{
            m: 1,
            width: `${100 % -drawerWidth}`,
            ml: drawerWidth,
          }}
        >
          <Toolbar />
          <CompTable
            target={target}
            comps={scoredComps}
            setMousedOverTransactionID={setMousedOverTransactionID}
          />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              bgcolor: "background.default",
              p: 3,
            }}
          ></Box>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Arv;
