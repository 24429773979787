import * as React from "react";
import {useState} from "react";
import Box from "@mui/material/Box";
import {useAuth0} from "@auth0/auth0-react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  AppBar,
  Avatar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";
import {styled} from "@mui/material/styles";
import {RefineKbar} from "@refinedev/kbar";
import {MapLatLng} from "./pages/arv/MapDrawer";
import FeedbackModal from "./FeedbackModal";

interface LoginBarProps {
  mapCenter: MapLatLng;
  displayAlert(message: string): void;
}

export default function LoginBar(props: LoginBarProps) {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const {user, loginWithRedirect, logout, isAuthenticated} = useAuth0();
  const onClick = () => {
    loginWithRedirect({
      appState: {
        // This has to be a pathname as we use React's useNavigate hook
        // to make the redirect after logging in.
        returnTo: window.location.pathname,
      },
    });
  };
  const onClickFeedback = (_: React.MouseEvent<HTMLElement>) => {
    setShowFeedbackModal(true);
  };
  const [anchorElOverflow, setAnchorElOverflow] =
    React.useState<null | HTMLElement>(null);
  const handleOpenOverflowMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElOverflow(event.currentTarget);
  };
  const handleCloseOverflowMenu = () => {
    setAnchorElOverflow(null);
  };
  let authButton = (
    <Button color="inherit" onClick={onClick}>
      Login
    </Button>
  );
  if (isAuthenticated) {
    authButton = (
      <>
        {user && <Avatar src={user.picture} alt={user.name} />}
        <Button
          color="inherit"
          onClick={() =>
            logout({
              logoutParams: {
                // This has to be an href. I think auth0 makes this redirect
                // and it needs to be an href. Be careful changing this.
                returnTo: window.location.href,
              },
            })
          }
        >
          Logout
        </Button>
      </>
    );
  }

  return (
    <StyledAppBar
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar>
        <RefineKbar />
        <Box
          sx={{
            "& > :not(style)": {m: 1, width: "95%"},
            minWidth: "1080px",
            flexGrow: 1,
          }}
        ></Box>
        {authButton}
        <>
          <FeedbackModal
            show={showFeedbackModal}
            setShow={setShowFeedbackModal}
            address={""}
            mapCenter={props.mapCenter}
            comps={[]}
            displayAlert={props.displayAlert}
          />
          <IconButton
            size="small"
            aria-label="feedback"
            color="inherit"
            onClick={handleOpenOverflowMenu}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorElOverflow}
            open={Boolean(anchorElOverflow)}
            onClose={handleCloseOverflowMenu}
            PaperProps={{
              style: {
                maxHeight: 48 * 4.5,
                width: "20ch",
              },
            }}
          >
            <MenuItem key="feedback" onClick={onClickFeedback}>
              Send Feedback
            </MenuItem>
          </Menu>
        </>
      </Toolbar>
    </StyledAppBar>
  );
}

const StyledAppBar = styled(AppBar)(({theme}) => ({
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  fontWeight: "bold",
}));
