import React, {createRef, useState} from "react";
import "../../App.css";
import CssBaseline from "@mui/material/CssBaseline";
import {Box, Stack, Toolbar} from "@mui/material";
import {Outlet} from "react-router-dom";
import LoginBar from "../../LoginBar";
import MapDrawer, {MapLatLng} from "../../pages/arv/MapDrawer";
import {Map as LeafletMap} from "leaflet";
import AlertBar from "../../AlertBar";
import {AlertBarProps} from "../../AlertBar";

export default function DealPage() {
  const drawerWidth = "40%";
  const indyGeocode: MapLatLng = {lat: 39.791, lng: -86.148003};
  const [geocodedAddress, _setGeocodedAddress] = useState<MapLatLng>();
  const [mapCenter, _setMapCenter] = useState<MapLatLng>(indyGeocode);
  const [zoomLevel, _setZoomLevel] = useState<number>(12);
  const leafletMap = createRef<LeafletMap>();
  const closeAlert = () => {
    setAlertBarProps({show: false, message: "", handleClose: closeAlert});
  };
  const displayAlert = (message: string) => {
    setAlertBarProps({
      show: true,
      message: message,
      handleClose: closeAlert,
    });
  };
  const [alertBarProps, setAlertBarProps] = useState<AlertBarProps>({
    show: false,
    message: "",
    handleClose: closeAlert,
  });
  return (
    <Stack sx={{display: "flex"}}>
      <AlertBar {...alertBarProps} handleClose={closeAlert} />
      <CssBaseline />
      <LoginBar mapCenter={mapCenter} displayAlert={displayAlert} />
      <Stack>
        <MapDrawer
          ref={leafletMap}
          drawerWidth={drawerWidth}
          geocodedAddress={geocodedAddress}
          mapCenter={mapCenter}
          zoomLevel={zoomLevel}
        />
        <Stack sx={{m: 1, width: `${100 % -drawerWidth}`, ml: drawerWidth}}>
          <Toolbar />
          <Outlet />
          <Box
            component="main"
            sx={{flexGrow: 1, bgColor: "background.default", p: 3}}
          ></Box>
        </Stack>
      </Stack>
    </Stack>
  );
}
