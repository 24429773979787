import {createAction, Priority, useRegisterActions} from "@refinedev/kbar";
import {DealType} from "./dataProvider/types";
import {DependencyList} from "react";
import {useCreate, useGo} from "@refinedev/core";

export const useKbarDealActions = (
  deals: DealType[],
  dependencies: DependencyList = []
) => {
  const go = useGo();
  const actions = deals.map((deal) =>
    createAction({
      name: deal.address ?? "Deal",
      section: "Deals",
      icon: "🏠",
      perform: () => {
        go({
          to: `/deal/${deal.id}`,
          type: "push",
        });
      },
      priority: Priority.HIGH,
    })
  );
  useRegisterActions(actions, dependencies);
};

export const useKbarDealCreateAction = (searchQuery: string) => {
  const {mutate} = useCreate<DealType>();
  const go = useGo();
  const name =
    searchQuery.length > 0
      ? `Create deal: ${searchQuery}`
      : "Enter your address.";
  const actions = [
    createAction({
      name: name,
      section: "Deals",
      icon: "🏠",
      perform: () => {
        console.info("Create new deal");
        mutate(
          {
            resource: "deal",
            values: {
              address: searchQuery,
              creator_auth0_uid: "Replaced by Trigger",
            },
            successNotification: false,
            errorNotification: (_data, _values, _resource) => {
              return {
                message: `Something went wrong when creating a deal`,
                description: "Error",
                type: "error",
              };
            },
          },
          {
            onError: (error) => {
              console.error("Error creating deal", error);
            },
            onSuccess: (data) => {
              if (!data.data?.id) {
                console.error("Failed to create deal.");
                return;
              }
              go({
                to: `/deal/${data.data.id}`,
                type: "push",
              });
            },
          }
        );
      },
      priority: Priority.HIGH,
    }),
  ];
  useRegisterActions(actions, [searchQuery]);
};
