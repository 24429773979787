import {useForm} from "@refinedev/react-hook-form";
import {DealType} from "../../dataProvider/types";
import React, {MouseEvent, useState} from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import WcOutlinedIcon from "@mui/icons-material/WcOutlined";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import NightlifeOutlinedIcon from "@mui/icons-material/NightlifeOutlined";
import DirectionsCarOutlinedIcon from "@mui/icons-material/DirectionsCarOutlined";
import {CurrencyTextField} from "../atoms/CurrencyTextField";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";
import {FieldErrors} from "react-hook-form/dist/types/errors";
import {useNotification} from "@refinedev/core";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import {PropertyInfo} from "../../PropertyInfo";

interface DealEditProps {
  lookupPropertyInfo(address: string): Promise<PropertyInfo>;
}

export const DealEdit = (props: DealEditProps) => {
  const [loadingProperty, setLoadingProperty] = useState(false);
  const {open: openNotification} = useNotification();
  const {
    refineCore: {queryResult, onFinish},
    handleSubmit,
    register,
    setValue,
    getValues,
    watch,
    control,
  } = useForm<DealType>({
    refineCoreProps: {
      redirect: false,
      successNotification: false,
      metaData: {
        id: "id",
      },
    },
  });
  const record: DealType | undefined = queryResult?.data?.data;
  const onInvalid = (
    errors: FieldErrors<DealType>,
    _event?: React.BaseSyntheticEvent
  ) => {
    console.error("Error encountered when attempting auto save.", errors);
    openNotification?.({
      type: "error",
      message: "Unable to auto-save the deal.",
      key: "error-auto-save",
    });
  };
  const onClickLookup = (_: MouseEvent<HTMLButtonElement>) => {
    setLoadingProperty(true);
    props
      .lookupPropertyInfo(getValues("address"))
      .then((info) => {
        setValue("bedrooms_total", info.bedrooms);
        setValue("bathrooms_total", info.bathrooms);
        setValue("living_area_sf", info.living_area_sf);
        setValue("build_year", info.build_year);
        setValue("garage_spaces", info.garage_spaces);
      })
      .catch((_error) => {
        openNotification?.({
          type: "error",
          message: "Unable to magic wand lookup the deal.",
          key: "error-magic-wand",
        });
      })
      .finally(() => {
        setLoadingProperty(false);
      });
  };
  React.useEffect(() => {
    // @ts-ignore to fix, define the types like this:
    // https://github.com/refinedev/refine/blob/next/examples/form-mui-use-form/src/pages/posts/edit.tsx#L17-L22
    const subscription = watch(handleSubmit(onFinish, onInvalid));
    // @ts-ignore
    return () => subscription.unsubscribe();
  }, [handleSubmit, onFinish, watch]);
  return (
    <Stack gap={1}>
      <Box sx={{"& > :not(style)": {m: 1}}}>
        <TextField
          id="input-with-icon-address"
          {...register("address", {
            required: "This field is required",
          })}
          label="Address"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <HomeOutlinedIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">
                <IconButton
                  size="small"
                  aria-label="lookup"
                  color="inherit"
                  onClick={onClickLookup}
                  disabled={loadingProperty}
                >
                  {loadingProperty ? (
                    <CircularProgress size="1em" />
                  ) : (
                    <AutoFixHighOutlinedIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          variant="standard"
          inputProps={{readOnly: true}}
        />
        <TextField
          id="input-with-icon-bedrooms"
          {...register("bedrooms_total", {
            valueAsNumber: true,
          })}
          label="Beds"
          InputLabelProps={{shrink: true}}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BedOutlinedIcon />
              </InputAdornment>
            ),
          }}
          variant="standard"
          inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
          sx={{width: "6ch"}}
        />
        <TextField
          id="input-with-icon-bathrooms"
          label="Baths"
          {...register("bathrooms_total", {
            valueAsNumber: true,
          })}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <WcOutlinedIcon />
              </InputAdornment>
            ),
          }}
          variant="standard"
          inputProps={{inputMode: "numeric", pattern: "[0-9]*[.5]?"}}
          sx={{width: "6ch"}}
        />
        <TextField
          id="input-with-icon-build-year"
          label="Build Year"
          {...register("build_year", {
            valueAsNumber: true,
          })}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CakeOutlinedIcon />
              </InputAdornment>
            ),
          }}
          variant="standard"
          inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
          sx={{width: "8ch"}}
        />
        <TextField
          id="input-with-icon-living-sf"
          label="Living sq ft."
          {...register("living_area_sf", {
            valueAsNumber: true,
          })}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <NightlifeOutlinedIcon />
              </InputAdornment>
            ),
          }}
          variant="standard"
          inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
          sx={{width: "8ch"}}
        />
        <TextField
          id="input-with-icon-garage-spaces"
          label="Garage"
          {...register("garage_spaces", {
            valueAsNumber: true,
          })}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <DirectionsCarOutlinedIcon />
              </InputAdornment>
            ),
          }}
          variant="standard"
          inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
          sx={{width: "6ch"}}
        />
      </Box>
      <Box sx={{"& > :not(style)": {m: 1}}}>
        <CurrencyTextField
          label="Offer Price"
          icon={<PaidOutlinedIcon />}
          name="offer_price"
          register={register}
          control={control}
          sx={{width: "12ch"}}
        />
        <CurrencyTextField
          label="After Repair Value"
          icon={<AccountBalanceOutlinedIcon />}
          name="after_repair_value"
          register={register}
          control={control}
          sx={{width: "12ch"}}
        />
        <CurrencyTextField
          label="Repair Estimate"
          icon={<ConstructionOutlinedIcon />}
          name="repair_estimate"
          register={register}
          control={control}
          sx={{width: "12ch"}}
        />
        <CurrencyTextField
          label="Profit"
          name="profit"
          icon={<PaidOutlinedIcon />}
          sx={{width: "12ch"}}
          value={
            record?.after_repair_value &&
            record?.offer_price &&
            record?.repair_estimate
              ? record.after_repair_value -
                record.offer_price -
                record.repair_estimate
              : ""
          }
        />
      </Box>
      <Box sx={{"& > :not(style)": {m: 1}}}>
        <TextField
          id="deal-review-body"
          label="Deal Review"
          {...register("review_body", {
            valueAsNumber: false,
          })}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CommentOutlinedIcon />
              </InputAdornment>
            ),
          }}
          variant="standard"
          inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
          sx={{width: "80ch"}}
          multiline
          rows={4}
        />
      </Box>
    </Stack>
  );
};
