import {BrowserRouter} from "react-router-dom";
import React, {useEffect, useState} from "react";
import AppLoadingIndicator from "./AppLoadingIndicator";
import Auth0ProviderWithNavigate from "./Auth0ProviderWithNavigate";
import AuthenticatedApp from "./AuthenticatedApp";
import {BootstrapConfig, getBootstrapConfig} from "./api";
import {SchemaName} from "./dataProvider/types";

const App: React.FC = () => {
  const [isBootstrapped, setIsBootstrapped] = useState<boolean>(false);
  const [auth0Domain, setAuth0Domain] = useState("");
  const [auth0ClientID, setAuth0ClientID] = useState("");
  const [dbSchema, setDbSchema] = useState<SchemaName>("local");

  useEffect(() => {
    getBootstrapConfig()
      .then((config: BootstrapConfig) => {
        setAuth0Domain(config.auth0_domain);
        setAuth0ClientID(config.auth0_client_id);
        setDbSchema(config.db_schema);
        setIsBootstrapped(true);
      })
      .catch(console.error);
  });
  if (!isBootstrapped) {
    return <AppLoadingIndicator />;
  }
  return (
    <BrowserRouter>
      <Auth0ProviderWithNavigate
        domain={auth0Domain}
        clientId={auth0ClientID}
        redirectUri={window.location.origin}
      >
        <AuthenticatedApp dbSchema={dbSchema} />
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  );
};

export default App;
