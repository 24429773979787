import * as React from "react";
import {ChangeEvent, FormEvent, MouseEvent, useState} from "react";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import DirectionsCarOutlinedIcon from "@mui/icons-material/DirectionsCarOutlined";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NightlifeOutlinedIcon from "@mui/icons-material/NightlifeOutlined";
import SearchIcon from "@mui/icons-material/Search";
import WcOutlinedIcon from "@mui/icons-material/WcOutlined";
import {useAuth0} from "@auth0/auth0-react";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
} from "@mui/material";
import {GetCompsRequest} from "../../api";
import {PropertyInfo} from "../../PropertyInfo";
import {alpha, styled} from "@mui/material/styles";
import FeedbackModal from "../../FeedbackModal";
import {MapLatLng} from "./MapDrawer";
import {Comp} from "../../Comp";

interface SearchBarProps {
  address: string;
  bedrooms?: number;
  bathrooms?: number;
  livingAreaSqFt?: number;
  buildYear?: number;
  hasGarage?: boolean;
  garageSpaces?: number;
  mapCenter: MapLatLng;
  comps: Array<Comp>;

  setAddress(address: string): void;

  lookupPropertyInfo(address: string): Promise<PropertyInfo>;

  lookupComps(getCompsRequest: GetCompsRequest): void;

  setBedrooms(bedrooms?: number): void;

  setBathrooms(bathrooms?: number): void;

  setLivingAreaSqFt(livingAreaSqFt?: number): void;

  setBuildYear(buildYear?: number): void;

  setHasGarage(hasGarage?: boolean): void;

  setGarageSpaces(garageSpaces?: number): void;

  displayAlert(message: string): void;
}

export default function SearchBar(props: SearchBarProps) {
  const [loadingProperty, setLoadingProperty] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const stringCallbacks: {[key: string]: (value: string) => void} = {
    address: props.setAddress,
  };
  const numberCallbacks: {[key: string]: (value: number) => void} = {
    bedrooms: props.setBedrooms,
    bathrooms: props.setBathrooms,
    livingAreaSqFt: props.setLivingAreaSqFt,
    buildYear: props.setBuildYear,
  };
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    if (target.type === "number") {
      numberCallbacks[target.name](Number(target.value));
    } else {
      stringCallbacks[target.name](target.value);
    }
  };
  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.lookupComps({
      address: props.address,
      bedrooms: props.bedrooms,
      bathrooms: props.bathrooms,
      living_area_sf: props.livingAreaSqFt,
      build_year: props.buildYear,
    });
  };
  const onClickLookup = (_: MouseEvent<HTMLButtonElement>) => {
    setLoadingProperty(true);
    props
      .lookupPropertyInfo(props.address)
      .then((info) => {
        props.setBedrooms(info.bedrooms);
        props.setBathrooms(info.bathrooms);
        props.setLivingAreaSqFt(info.living_area_sf);
        props.setBuildYear(info.build_year);
        props.setHasGarage(info.has_garage);
        props.setGarageSpaces(info.garage_spaces);
        if (!info.address) {
          return;
        }
        props.setAddress(info.address);
        props.lookupComps({
          address: info.address,
          bedrooms: info.bedrooms,
          bathrooms: info.bathrooms,
          living_area_sf: info.living_area_sf,
          build_year: info.build_year,
        });
      })
      .catch((error) => {
        console.log(error);
        props.displayAlert(
          "Oops, something went wrong. Please try again later."
        );
      })
      .finally(() => {
        setLoadingProperty(false);
      });
  };
  const onClickFeedback = (_: React.MouseEvent<HTMLElement>) => {
    setShowFeedbackModal(true);
    setAnchorElOverflow(null);
  };
  const [anchorElOverflow, setAnchorElOverflow] =
    React.useState<null | HTMLElement>(null);
  const handleOpenOverflowMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElOverflow(event.currentTarget);
  };
  const handleCloseOverflowMenu = () => {
    setAnchorElOverflow(null);
  };
  const {user, loginWithRedirect, logout, isAuthenticated} = useAuth0();
  let authButton = (
    <Button color="inherit" onClick={() => loginWithRedirect()}>
      Login
    </Button>
  );
  if (isAuthenticated) {
    authButton = (
      <>
        {user && <Avatar src={user.picture} alt={user.name} />}
        <Button
          color="inherit"
          onClick={() =>
            logout({
              logoutParams: {returnTo: window.location.origin},
            })
          }
        >
          Logout
        </Button>
      </>
    );
  }

  return (
    <StyledAppBar
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar>
        <Box
          component="form"
          sx={{
            "& > :not(style)": {m: 1, width: "95%"},
            minWidth: "1080px",
            flexGrow: 1,
          }}
          noValidate
          autoComplete="off"
          onSubmit={onSubmit}
        >
          <Stack direction="row" spacing={0}>
            <SearchBox>
              <SearchIconWrapper>
                <HomeOutlinedIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Address"
                inputProps={{"aria-label": "search"}}
                name="address"
                value={props.address}
                type="string"
                onChange={onChange}
                sx={{width: "22ch"}}
              />
              <IconButton
                size="small"
                aria-label="lookup"
                color="inherit"
                onClick={onClickLookup}
                disabled={loadingProperty}
              >
                {loadingProperty ? (
                  <CircularProgress size="1em" />
                ) : (
                  <AutoFixHighOutlinedIcon />
                )}
              </IconButton>
            </SearchBox>
            <SearchBox>
              <SearchIconWrapper>
                <BedOutlinedIcon />
              </SearchIconWrapper>
              <StyledInputBase
                id="outlined-controlled"
                name="bedrooms"
                placeholder="Bed"
                value={props.bedrooms || ""}
                type="number"
                onChange={onChange}
                sx={{width: "10ch"}}
              />
            </SearchBox>
            <SearchBox>
              <SearchIconWrapper>
                <WcOutlinedIcon />
              </SearchIconWrapper>
              <StyledInputBase
                id="outlined-controlled"
                name="bathrooms"
                placeholder="Bath"
                value={props.bathrooms || ""}
                type="number"
                onChange={onChange}
                sx={{width: "10ch"}}
              />
            </SearchBox>
            <SearchBox>
              <SearchIconWrapper>
                <CakeOutlinedIcon />
              </SearchIconWrapper>
              <StyledInputBase
                id="outlined-controlled"
                name="buildYear"
                placeholder="Year"
                value={props.buildYear || ""}
                type="number"
                onChange={onChange}
                sx={{width: "12ch"}}
              />
            </SearchBox>
            <SearchBox>
              <SearchIconWrapper>
                <NightlifeOutlinedIcon />
              </SearchIconWrapper>
              <StyledInputBase
                id="outlined-controlled"
                name="livingAreaSqFt"
                placeholder="Living SF"
                value={props.livingAreaSqFt || ""}
                type="number"
                onChange={onChange}
                sx={{width: "15ch"}}
              />
            </SearchBox>
            <SearchBox>
              <SearchIconWrapper>
                <DirectionsCarOutlinedIcon />
              </SearchIconWrapper>
              <StyledInputBase
                id="outlined-controlled"
                name="garageSpaces"
                placeholder="Garage Spaces"
                value={props.garageSpaces || ""}
                type="number"
                onChange={onChange}
                sx={{width: "15ch"}}
              />
            </SearchBox>
            <IconButton
              size="small"
              aria-label="search"
              color="inherit"
              type="submit"
            >
              <SearchIcon />
            </IconButton>
          </Stack>
        </Box>
        {authButton}
        <>
          <FeedbackModal
            show={showFeedbackModal}
            setShow={setShowFeedbackModal}
            address={props.address}
            mapCenter={props.mapCenter}
            comps={props.comps}
            displayAlert={props.displayAlert}
          />
          <IconButton
            size="small"
            aria-label="feedback"
            color="inherit"
            onClick={handleOpenOverflowMenu}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorElOverflow}
            open={Boolean(anchorElOverflow)}
            onClose={handleCloseOverflowMenu}
            PaperProps={{
              style: {
                maxHeight: 48 * 4.5,
                width: "20ch",
              },
            }}
          >
            <MenuItem key="feedback" onClick={onClickFeedback}>
              Send Feedback
            </MenuItem>
          </Menu>
        </>
      </Toolbar>
    </StyledAppBar>
  );
}

const StyledAppBar = styled(AppBar)(({theme}) => ({
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  fontWeight: "bold",
}));

const SearchBox = styled("div")(({theme}) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({theme}) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));
